/* eslint-disable camelcase */
/* eslint-disable no-extra-boolean-cast */
/* eslint-disable prefer-destructuring */
import { filterKnowledge } from '../utils/filterKnowledge';
import { countries } from '../utils/countries';
import CustomLRU from '../utils/customLRU';

var treeBuilderOptions = {
  assigneeUserId: true,
  assigneeGroupId: true,
  attachment: true,
  emailcc: true,
  status: true,
  image: true,
  link: true,
  embeddableExtension: true,
  strike: true,
  divider: true,
  code: true,
  headings: true,
  underline: true,
};

const zendeskBlockedOptions = ['attachment', 'code', 'underline'];
const intercomBlockedOptions = [
  'assigneeUserId',
  'assigneeGroupId',
  'emailcc',
  'status',
  'divider',
  'code',
  'headings',
  'underline',
];
const knowledgeBlockedOptions = [
  'assigneeUserId',
  'assigneeGroupId',
  'emailcc',
  'status',
  'code',
  'embeddableExtension',
];

const ADMIN_PERMISSIONS = [
  'EDIT_KNOWLEDGE_BASE',
  'READ_ANALYTICS_DASHBOARDS',
  'EDIT_COMPANY_USERS',
  'EDIT_AGENT_NOTIFICATION',
  'READ_TASKS',
  'EDIT_COMPANY_SETTINGS',
];

export const blobVersion = (state) => state.blobVersion;

export const customConfig = (state) => state.customConfig;

export const userAccess = (state) => {
  const { userPermissions } = state;
  return {
    knowledgeEditor: userPermissions.includes('EDIT_KNOWLEDGE_BASE'),
    dashboards: userPermissions.includes('READ_ANALYTICS_DASHBOARDS'),
    notifications: userPermissions.includes('EDIT_AGENT_NOTIFICATION'),
    tasksBoard: userPermissions.some((permission) =>
      ['READ_TASK'].includes(permission),
    ),
    automations: userPermissions.includes('EDIT_KNOWLEDGE_BASE'),
    settings:
      userPermissions.includes('EDIT_COMPANY_SETTINGS') ||
      userPermissions.includes('EDIT_COMPANY_USERS'),
    search: userPermissions.includes('EDIT_KNOWLEDGE_BASE'),
    publish: userPermissions.includes('EDIT_KNOWLEDGE_BASE'),
    ask: userPermissions.includes('BRAIN_ENABLE_ASK_ADMIN_BETA'),
    academy: userPermissions.some((permission) =>
      [
        'CREATE_MODULE',
        'UPDATE_MODULE',
        'DELETE_MODULE',
        'ASSIGN_MODULE',
        'RESTRICT_MODULE',
        'PRESENT_MODULE',
      ].includes(permission),
    ),
  };
};

export const maxAuthAttempts = (state) => state.authAttempts || 0;

export const warningAttempts = (state) => state.authAttempts >= 3;

export const tooManyAttempts = (state) => state.authAttempts > 4;

export const loggedIn = (state) => !!state.accessToken;

export const sessionActive = (state) => state.accessToken; // && Date.now() < state.accessExpirationDate;

export const isAdmin = (state) => {
  return state.userPermissions.some((permission) =>
    ADMIN_PERMISSIONS.includes(permission),
  );
};

export const hasPermission = (state) => (permission) => {
  const { userPermissions } = state;
  return userPermissions.includes(permission);
};
export const companyRoles = (state) => state.roles;
export const getIntegrations = (state) => state.integrations;
export const getImportIntegrations = (state) =>
  state.integrations.filter((integration) =>
    ['Salesforce', 'Zendesk', 'Notion'].includes(integration),
  );
export const companyLanguages = (state) =>
  state.languages.map((o) => o.language);
export const processedCompanyLanguages = (state) =>
  state.languages.map((o) => {
    return {
      id: o.id,
      language: o.language,
      name: countries()[o.language],
    };
  });
export const isMultiLingual = (state) =>
  state.languages ? state.languages.length > 1 : false;
export const hasZendesk = (state) => state.integrations.includes('Zendesk');
export const hasAgentRestrictions = (state) =>
  state.companyPreferences.filter(
    (v) =>
      v.type === 'ZENDESK_SEARCH_NO_STEPS' ||
      v.type === 'WEB_EXT_SEARCH_NO_STEPS' ||
      v.type === 'MAYDAY_WEB_SEARCH_NO_STEPS',
  ).length > 0;
export const hideReadNotifications = (state) =>
  state.companyPreferences.find(
    (v) => v.type === 'UNREAD_NOTIFICATIONS_ONLY',
  ) !== undefined;
export const hasChrome = (state) => state.integrations.includes('Chrome');
export const hasIntercom = (state) => state.integrations.includes('Intercom');
export const hasIadvize = (state) => state.integrations.includes('Iadvize');
export const hasNotion = (state) => state.integrations.includes('Notion');
export const hasSalesforce = (state) =>
  state.integrations.includes('Salesforce');
export const optionsDisplayed = (state) => {
  var options = JSON.parse(JSON.stringify(treeBuilderOptions));
  if (state.integrations.includes('Zendesk')) {
    zendeskBlockedOptions.forEach((o) => {
      options[o] = false;
    });
  }
  if (state.integrations.includes('Intercom')) {
    intercomBlockedOptions.forEach((o) => {
      options[o] = false;
    });
  }
  if (state.integrations.includes('Knowledge')) {
    knowledgeBlockedOptions.forEach((o) => {
      options[o] = false;
    });
  }
  return options;
};

export const navigationNodes = (state) => {
  const { focusPath, treeNodes } = state;
  const navNodes = focusPath.map((id) =>
    treeNodes.find((node) => String(node.id) === String(id)),
  );
  return navNodes.filter((node) =>
    node === undefined ? false : !['Step', 'keyStep'].includes(node.type),
  );
};

export const navigationPath = (_state, getters) => {
  const { navigationNodes } = getters;
  if (navigationNodes) return navigationNodes.map((node) => node.id);
  return [];
};

export const navigationTags = (state, getters) => {
  const { nodesContent } = state;
  const { navigationNodes } = getters;
  return navigationNodes.map(
    (node) => nodesContent.find((c) => c.id === node.contentId).label,
  );
};

export const focusNavNode = (state, getters) => {
  const { navigationNodes } = getters;
  return navigationNodes.slice(-1)[0];
};

export const focusNavNodeContent = (state, getters) =>
  state.nodesContent.find(
    (content) => content.id === getters.focusNavNode.contentId,
  );

export const buildNodes = (state) => {
  const { focusPath, treeNodes } = state;
  const navNodes = focusPath.map((id) =>
    treeNodes.find((node) => String(node.id) === String(id)),
  );
  return navNodes.filter((node) =>
    node === undefined
      ? false
      : ['Diagnostic', 'Step', 'keyStep'].includes(node.type),
  );
};

export const buildPath = (_state, getters) => {
  const { buildNodes } = getters;
  if (buildNodes) return buildNodes.map((node) => node.id);
  return [];
};

export const buildTree = (state, getters) => {
  const { navigationPath } = getters;
  const { focusTree } = state;
  let buildTree = {};
  if (focusTree !== {}) {
    buildTree = {
      ...focusTree,
    };
    navigationPath.slice(1).forEach((parentId) => {
      buildTree = buildTree.nodes.find(({ id }) => String(id) === parentId);
    });
  }
  return buildTree;
};

export const tags = (state) => {
  const { focusPath, focusTree } = state;
  const tags = [];
  if (focusTree !== {}) {
    tags.push(focusTree.content.label);
    let trans = {
      ...focusTree,
    };
    focusPath.slice(1).forEach((parentId) => {
      if (trans) {
        trans = trans.nodes.find(({ id }) => String(id) === parentId);
        if (trans) tags.push(trans.content.label);
      }
    });
  }
  return tags;
};

export const focusKnowledgeId = (state) => state.focusKnowledge.id;

export const focusNodeIsPublished = (getters) => {
  const { buildTree } = getters;
  let isPublished = false;
  if (buildTree && buildTree !== {}) {
    isPublished = buildTree.content.published;
  }
  return isPublished;
};

export const triggerEmbeddableExtensionModal = (state) =>
  !!state.embeddableExtensionClickEvent;

export const focusNode = (state) =>
  state.treeNodes.find((node) => node.id === state.focusPath.slice(-1)[0]);

export const linkedNodesMapping = (state) => {
  let linkedNodes = {};
  state.treeNodes.forEach((node) => {
    if (linkedNodes[node.contentId]) {
      linkedNodes[node.contentId].push(node.id);
    } else {
      linkedNodes[node.contentId] = [node.id];
    }
  });
  return linkedNodes;
};

export const filteredTreeNodes = (state) => {
  const { knowledgeFilters, treeNodes, nodesContent } = state;

  return knowledgeFilters.length
    ? filterKnowledge(knowledgeFilters, treeNodes, nodesContent)
    : [];
};

export const obsoleteArticlesDelay = (state) => {
  const { companyPreferences } = state;
  const preference = companyPreferences.find(
    (o) => o.type === 'ALLOW_OBSOLETE_ARTICLES_NOTIFICATIONS',
  );
  return preference ? preference.value : 15;
};

export const obsoleteDiagnosticsDelay = (state) => {
  const { companyPreferences } = state;
  const preference = companyPreferences.find(
    (o) => o.type === 'ALLOW_OBSOLETE_DIAGNOSTICS_NOTIFICATIONS',
  );
  return preference ? preference.value : 15;
};

export const companyPrimaryColorPreference = (state) => {
  const { companyPreferences } = state;
  return companyPreferences.find((o) => o.type === 'PRIMARY_COLOR');
};

export const companySecondaryColorPreference = (state) => {
  const { companyPreferences } = state;
  return companyPreferences.find((o) => o.type === 'SECONDARY_COLOR');
};

export const companyWebExtPrimaryColorPreference = (state) => {
  const { companyPreferences } = state;
  return companyPreferences.find((o) => o.type === 'WEB_EXT_PRIMARY_COLOR');
};

export const companyWebExtSecondaryColorPreference = (state) => {
  const { companyPreferences } = state;
  return companyPreferences.find((o) => o.type === 'WEB_EXT_SECONDARY_COLOR');
};

export const companySearchAutocompletePreference = (state) => {
  const { companyPreferences } = state;
  const preference = companyPreferences.find(
    (o) => o.type === 'SEARCH_AUTOCOMPLETE',
  );
  return preference;
};

export const companyAllowCompanyFavoritesPreference = (state) => {
  const { companyPreferences } = state;
  const preference = companyPreferences.find(
    (o) => o.type === 'ALLOW_COMPANY_FAVORITES',
  );
  return preference;
};

export const companyAllowTrendingContentPreference = (state) => {
  const { companyPreferences } = state;
  const preference = companyPreferences.find(
    (o) => o.type === 'ALLOW_TRENDING_CONTENT',
  );
  return preference;
};

export const companyAllowNewContentPreference = (state) => {
  const { companyPreferences } = state;
  const preference = companyPreferences.find(
    (o) => o.type === 'DESK_NEW_CONTENTS_PLUGIN',
  );
  return preference;
};

export const companyAllowEmbeddedIframePreference = (state) => {
  const { companyPreferences } = state;
  const preference = companyPreferences.find(
    (o) => o.type === 'ALLOW_EMBEDDED_IFRAME',
  );
  return preference;
};

export const companyAllowSmartActionsPreference = (state) => {
  const { companyPreferences } = state;
  const preference = companyPreferences.find(
    (o) => o.type === 'ALLOW_SMART_ACTIONS',
  );
  return preference;
};

export const isPluginPreferenceAllowed =
  ({ companyPreferences }) =>
  (pluginType) =>
    companyPreferences.find(({ type }) => type === pluginType);

export const companyAllowToastSoundPreference = (state) => {
  const { companyPreferences } = state;
  const preference = companyPreferences.find(
    (o) => o.type === 'ALLOW_TOAST_SOUND',
  );
  return preference;
};

export const companyApplyExclusiveSearchPreference = (state) => {
  const { companyPreferences } = state;
  const preference = companyPreferences.find(
    (o) => o.type === 'SEARCH_CATEGORIES_AND_FILTER',
  );
  return preference;
};

export const companySearchUserBoostPreference = (state) => {
  const { companyPreferences } = state;
  const preference = companyPreferences.find(
    (o) => o.type === 'SEARCH_USER_CONSULTATION_BOOST',
  );
  if (preference && preference.value === '1') return preference;
  return null;
};

export const companyAllowSummaryPreference = (state) => {
  const { companyPreferences } = state;
  const preference = companyPreferences.find(
    (o) => o.type === 'BRAIN_ENABLE_SUMMARY',
  );
  return preference && preference.value === '1';
};

export const companyAllowTranslationPreference = (state) => {
  const { companyPreferences } = state;
  const preference = companyPreferences.find(
    (o) => o.type === 'BRAIN_ENABLE_TRANSLATION',
  );
  return preference && preference.value === '1';
};

export const companyAllowGrammarCorrectionPreference = (state) => {
  const { companyPreferences } = state;
  const preference = companyPreferences.find(
    (o) => o.type === 'BRAIN_ENABLE_CORRECT_GRAMMAR',
  );
  return preference && preference.value === '1';
};

export const companyAllowToneImprovementPreference = (state) => {
  const { companyPreferences } = state;
  const preference = companyPreferences.find(
    (o) => o.type === 'BRAIN_ENABLE_CORRECT_TONE',
  );
  return preference && preference.value === '1';
};

export const companyAllowActionItemsPreference = (state) => {
  const { companyPreferences } = state;
  const preference = companyPreferences.find(
    (o) => o.type === 'BRAIN_ENABLE_ACTION_ITEMS',
  );
  return preference && preference.value === '1';
};
export const companyAllowAutomaticTranslationPreference = (state) => {
  const { companyPreferences } = state;
  const preference = companyPreferences.find(
    (o) => o.type === 'BRAIN_ENABLE_AUTOMATIC_TRANSLATION',
  );
  return preference && preference.value === '1';
};

export const companyAllowLinkSeoAttributesPreference = (state) => {
  const { companyPreferences } = state;
  const preference = companyPreferences.find(
    (o) => o.type === 'ALLOW_LINK_SEO_ATTRIBUTES',
  );
  return preference && preference.value === '1';
};

export const companyAllowDecisionTreePreference = (state) => {
  const { companyPreferences } = state;
  const preference = companyPreferences.find(
    (o) => o.type === 'BRAIN_ENABLE_DECISION_TREE',
  );
  return preference && preference.value === '1';
};

export const companyAllowAcademyPreference = (state) => {
  const { companyPreferences } = state;
  const preference = companyPreferences.find((o) => o.type === 'ALLOW_ACADEMY');
  return preference && preference.value === '1';
};

export const companyAllowAskInSearchPreference = (state) => {
  const { companyPreferences } = state;
  const preference = companyPreferences.find(
    (o) => o.type === 'BRAIN_ENABLE_ASK_ADMIN_SEARCH',
  );
  return preference;
};
export const userAllowAskInSearchPreference = (state) => {
  const { userPreferences } = state;
  const preference = userPreferences.find(
    (o) => o.preference === 'ASK_ADMIN_SEARCH',
  );

  return preference;
};
export const userAllowConceptsPreference = (state) => {
  const { userPreferences } = state;
  const preference = userPreferences.find(
    (o) => o.preference === 'CONCEPTS_IN_ADMIN',
  );

  return preference;
};

export const companyAllowConceptsPreference = (state) => {
  const { companyPreferences } = state;
  const preference = companyPreferences.find(
    (o) => o.type === 'BRAIN_ENABLE_CONCEPTS',
  );
  return preference;
};

export const userAllowAskDevPreference = (state) => {
  const { userPreferences } = state;
  const preference = userPreferences.find(
    (o) => o.preference === 'ASK_ADMIN_DEV',
  );

  return preference;
};

export const companyColor = (state) => {
  const companyColor = state.companyPreferences.find(
    (cp) => cp.type === 'PRIMARY_COLOR',
  );
  return companyColor ? companyColor.value : '#0081f9';
};

export const companySecondaryColor = (state) => {
  const companyColor = state.companyPreferences.find(
    (cp) => cp.type === 'SECONDARY_COLOR',
  );
  return companyColor ? companyColor.value : '#0081f9';
};

export const companyWebExtPrimaryColor = (state, getters) => {
  const color = state.companyPreferences.find(
    (cp) => cp.type === 'WEB_EXT_PRIMARY_COLOR',
  );
  return color ? color.value : getters.companyColor;
};

export const companyWebExtSecondaryColor = (state, getters) => {
  const color = state.companyPreferences.find(
    (cp) => cp.type === 'WEB_EXT_SECONDARY_COLOR',
  );
  return color ? color.value : getters.companyColor;
};

export const companyWebExtIcon = (state) => {
  const companyIcon = state.companyPreferences.find(
    (cp) => cp.type === 'WEB_EXT_ICON',
  );
  return companyIcon ? companyIcon.value : '';
};

export const companyMaydayWebPrimaryColor = (state, getters) => {
  const color = state.companyPreferences.find(
    (cp) => cp.type === 'MAYDAY_WEB_PRIMARY_COLOR',
  );
  return color ? color.value : getters.companyColor;
};

export const companyMaydayWebSecondaryColor = (state, getters) => {
  const color = state.companyPreferences.find(
    (cp) => cp.type === 'MAYDAY_WEB_SECONDARY_COLOR',
  );
  return color ? color.value : getters.companyColor;
};

export const companyMaydayWebIcon = (state) => {
  const companyIcon = state.companyPreferences.find(
    (cp) => cp.type === 'MAYDAY_WEB_ICON',
  );
  return companyIcon ? companyIcon.value : '';
};
export const companyMaydayWebBanner = (state) => {
  const companyBanner = state.companyPreferences.find(
    (cp) => cp.type === 'MAYDAY_WEB_MAIN_PAGE_COVER',
  );
  return companyBanner ? companyBanner.value : '';
};

export const companyAllowFilterFocusCase = (state) => {
  const preference = state.companyPreferences.find(
    (cp) => cp.type === 'ALLOW_FILTER_FOCUS_CASE',
  );
  return preference ? preference : '';
};

export const companyAllowMultilingualKnowledgeSettings = (state) => {
  const preference = state.companyPreferences.find(
    (cp) => cp.type === 'ALLOW_MULTILINGUAL_KNOWLEDGE_SETTINGS',
  );
  return preference;
};

export const companyLogo = (state) => {
  return (
    state.companyLogo ||
    'https://maydaystatic.blob.core.windows.net/assets/mayday-rounded.png'
  );
};

export const hasCompanyLogo = (state) => !!state.companyLogo;

export const companyIadvizePrimaryColor = (state) => {
  const companyColor = state.companyPreferences.find(
    (cp) => cp.type === 'IDZ_PRIMARY_COLOR',
  );
  return companyColor ? companyColor.value : '#0081f9';
};

export const companyIadvizeSecondaryColor = (state) => {
  const companyColor = state.companyPreferences.find(
    (cp) => cp.type === 'IDZ_SECONDARY_COLOR',
  );
  return companyColor ? companyColor.value : '#0081f9';
};

export const companyZendeskPrimaryColor = (state) => {
  const companyColor = state.companyPreferences.find(
    (cp) => cp.type === 'ZD_PRIMARY_COLOR',
  );
  return companyColor ? companyColor.value : '#0081f9';
};

export const companyZendeskSecondaryColor = (state) => {
  const companyColor = state.companyPreferences.find(
    (cp) => cp.type === 'ZD_SECONDARY_COLOR',
  );
  return companyColor ? companyColor.value : '#0081f9';
};

export const isParametric = ({ companyPreferences }) =>
  companyPreferences.findIndex(({ type }) => type === 'IS_PARAMETRIC') > -1;
export const knowledgeRedirection = ({ companyPreferences }) => {
  const preference = companyPreferences.find(
    ({ type }) => type === 'KNOWLEDGE_REDIRECTION',
  );
  return preference ? preference.value : null;
};
export const isGranularAccessRestrictions = ({ companyPreferences }) =>
  companyPreferences.findIndex(
    ({ type }) => type === 'IS_GRANULAR_ACCESS_RESTRICTIONS',
  ) > -1;
export const hasContributions = ({ companyPreferences }) =>
  companyPreferences.find(({ type }) => type === 'HAS_CONTRIBUTIONS');

export const isSplitKnowledgeViewer = ({ companyPreferences }) =>
  !!companyPreferences.find(
    ({ type }) => type === 'IS_SPLIT_KNOWLEDGE_VIEWER',
  ) || false;

export const hasCompanyPreferenceWithValue =
  ({ companyPreferences }) =>
  (type, value = null) =>
    !!companyPreferences.find((p) => {
      if (p.type !== type) return false;
      if (!value) return true;
      return p.value === value;
    }) || false;

export const getCompanyPreference =
  ({ companyPreferences }) =>
  (type) =>
    companyPreferences.find((p) => p.type === type);

export const reachableUsers = (state, getters) => {
  return getters.isParametric
    ? state.reachableUsers
    : state.webKnowledgeModule.reachableUsers;
};

export const reachableRoles = (state, getters) => {
  return getters.isParametric
    ? state.reachableRoles
    : state.webKnowledgeModule.reachableRoles;
};

export const reachableRecipients = (_state, getters) => {
  const res = [];
  if (getters.reachableRoles) {
    res.push({
      label: 'Groupes',
      options: getters.reachableRoles
        .map((role) => ({
          type: 'role',
          value: role.id,
          label: role.name,
        }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    });
  }
  if (getters.reachableUsers) {
    res.push({
      label: 'Utilisateurs',
      options: getters.reachableUsers
        .map((user) => ({
          type: 'user',
          value: user.id,
          label: user.username,
        }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    });
  }
  return res;
};

export const companyAttributesGroupedByIntegration = (state) => {
  const { companyAttributes } = state;

  try {
    if (!companyAttributes || !companyAttributes.length) return {};
    return companyAttributes.reduce((acc, el) => {
      if (acc[el.source]) acc[el.source].push(el);
      else acc[el.source] = [el];
      return acc;
    }, {});
  } catch (e) {
    console.log(e);
    return {};
  }
};

export const salesforceCompanyAttributes = (_state, getters) => {
  return getters.companyAttributesGroupedByIntegration['salesforce'];
};

export const username = (state) => state.username;

export const avatar = (state) => state.avatar;

export const userId = (state) => state.userId;

export const orderedUserRecentlyConsultedContents =
  (state) => (focusKnowledgeValue) => {
    const { userRecentlyConsultedContents } = state;
    const asLRU = new CustomLRU({
      state: userRecentlyConsultedContents,
    });

    return asLRU.entries({ upperKey: focusKnowledgeValue });
  };
