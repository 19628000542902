import gql from 'graphql-tag';
import {
  CONTENT_FRAGMENT,
  PUBLIC_DATA_FRAGMENT,
  WORKFLOW_ACTION_FRAGMENT,
} from './content.fragments';

export default {
  createContent: gql`
    mutation createContent(
      $knowledge: String!
      $knowledgeId: ID!
      $type: String!
      $label: String!
      $parameters: JSON!
      $caseParameters: [String!]
      $accessRestrictions: [ID!]
      $labels: [ID!]
      $templateId: ID
      $lang: String
    ) {
      createdContent: createContent(
        knowledge: $knowledge
        knowledgeId: $knowledgeId
        type: $type
        label: $label
        parameters: $parameters
        caseParameters: $caseParameters
        accessRestrictions: $accessRestrictions
        labels: $labels
        templateId: $templateId
        lang: $lang
      ) {
        id
        caseParameters
        defaultLanguage
        returnLanguage
        setLanguages
        userPermissions
        type
      }
    }
  `,
  addChild: gql`
    mutation addChildMutation(
      $parentId: ID!
      $type: String!
      $label: String!
      $lang: String
    ) {
      addChild(parentId: $parentId, type: $type, label: $label, lang: $lang) {
        id
        type
        label
        body
        redirection {
          entityId
          entityType
          isDeleted
          lang
        }
        defaultLanguage
        returnLanguage
        setLanguages
      }
    }
  `,
  createContribution: gql`
    mutation createContributionMutation(
      $knowledge: String!
      $label: String!
      $body: String!
      $parameters: [JSON!]
      $attachments: [ID]
    ) {
      createContribution(
        knowledge: $knowledge
        label: $label
        body: $body
        parameters: $parameters
        attachments: $attachments
      )
    }
  `,
  addSatisfactionMark: gql`
    mutation addSatisfactionMark(
      $contentId: ID!
      $thumbType: String!
      $lang: String
    ) {
      satisfactionMark: addSatisfactionMark(
        contentId: $contentId
        thumbType: $thumbType
        lang: $lang
      ) {
        id
        contentId
        userId
        thumbType
      }
    }
  `,
  setContentRedirection: gql`
    mutation setContentRedirection(
      $contentId: ID!
      $entityId: ID
      $entityType: String
      $lang: String
    ) {
      contentRedirection: setContentRedirection(
        contentId: $contentId
        entityId: $entityId
        entityType: $entityType
        lang: $lang
      )
    }
  `,
  getContentById: gql`
    ${PUBLIC_DATA_FRAGMENT}
    ${WORKFLOW_ACTION_FRAGMENT}
    query getContentByIdQuery($id: ID!, $lang: String) {
      getContentById(id: $id, lang: $lang) {
        id
        type
        knowledgeId: knowledge
        label
        labelTranslations
        body
        contentUpdatedAt
        defaultLanguage
        returnLanguage
        setLanguages
        attachments
        tags
        attachedObjects
        published
        publishedTranslations
        isHidden
        isOutdated
        icon
        cover
        path
        userPermissions
        children {
          id
          label
          type
          path
          redirection {
            entityId
            entityType
            isDeleted
            lang
          }
          returnLanguage
        }
        parameters
        enrichedParameters
        caseParameters
        enrichedAuthor {
          id
          username
        }
        accessRestrictions
        isGeneric
        labels {
          id
          labelName
          icon
          color
        }
        satisfactionRates {
          thumbUpPercent
          thumbUpCount
          thumbDownPercent
          thumbDownCount
          totalThumbCount
        }
        redirection {
          entityId
          entityType
          isDeleted
          lang
        }
        publicData {
          ...publicDataFragment
        }
        relatedContents
        enrichedRelatedContents {
          id
          type
          label
          published
        }
        parents
        isReadOnly
        verificationPolicy {
          verificationRange
          verificationDueDate
          setToDraftAtDueDate
          userId
          customRoleId
        }
        publicationPolicy {
          publicationDueDate
        }
        actions {
          id
          payload
          type
          langs
          mongoId
          title
        }
        updatedAt
        createdAt
        summary {
          body
          bodyUpdatedAt
          updatedAt
          manualEdit
          isVisible
        }
        shortSummary {
          body
          bodyUpdatedAt
          updatedAt
          manualEdit
          isVisible
        }
        actionItems {
          body
          bodyUpdatedAt
          updatedAt
          manualEdit
          isVisible
        }
        workflowActions {
          ...workflowActionFragment
        }
        enrichedMentions {
          id
          username
          isDeleted
        }
        plugins
        denormalizedConcepts {
          conceptId
          status
          translations
          defaultLanguage
        }
        lockIds
      }
    }
  `,
  getContentChildrenById: gql`
    query getContentByIdQuery($id: ID!, $lang: String) {
      getContentById(id: $id, lang: $lang) {
        id
        type
        knowledgeId: knowledge
        label
        children {
          id
          label
          body
          type
          path
          children {
            id
            label
            body
            type
            path
          }
        }
        parents
      }
    }
  `,
  getLightContentById: gql`
    query getLightContentByIdQuery($id: ID!, $lang: String) {
      getContentById(id: $id, lang: $lang) {
        id
        label
        type
        published
        body
        isHidden
      }
    }
  `,
  getContentConceptsById: gql`
    query getContentConceptsById($id: ID!, $lang: String) {
      getContentById(id: $id, lang: $lang) {
        denormalizedConcepts {
          conceptId
          status
          defaultLanguage
          translations
        }
      }
    }
  `,
  getLoadingLanguages: gql`
    query getLoadingLanguages($id: ID!) {
      getLoadingLanguages(id: $id)
    }
  `,
  getBacklinksById: gql`
    query getContentBacklinksQuery($contentId: ID!) {
      getContentBacklinks(contentId: $contentId) {
        documents {
          ... on Content {
            __typename
            id
            label
            type
            ancestors
            breadcrumb
          }
        }
      }
    }
  `,
  getContentByIdWithAncestors: gql`
    ${WORKFLOW_ACTION_FRAGMENT}
    query getContentByIdWithAncestorsQuery(
      $id: ID!
      $lang: String
      $rootId: ID
    ) {
      getContentByIdWithAncestors: getContentById(id: $id, lang: $lang) {
        id
        type
        knowledgeId: knowledge
        label
        body
        defaultLanguage
        returnLanguage
        setLanguages
        attachments
        tags
        attachedObjects
        published
        isOutdated
        isHidden
        icon
        path
        userPermissions
        children {
          id
          label
          type
          path
          redirection {
            entityId
            entityType
            isDeleted
            lang
          }
          returnLanguage
        }
        ancestors
        ancestorsContent(rootId: $rootId) {
          id
          label
          type
          children {
            id
            label
            type
            redirection {
              entityId
              entityType
              isDeleted
              lang
            }
          }
        }
        parameters
        caseParameters
        author
        accessRestrictions
        isGeneric
        labels {
          id
          labelName
          icon
          color
        }
        redirection {
          entityId
          entityType
          isDeleted
          lang
        }
        parents
        isReadOnly
        actions {
          id
          payload
          type
          langs
          mongoId
          title
        }
        workflowActions {
          ...workflowActionFragment
        }
        createdAt
        updatedAt
        plugins
      }
    }
  `,
  getWithEnriched: gql`
    query getWithEnrichedQuery($id: ID!, $skipAncestors: Boolean!) {
      getWithEnriched: getContentById(id: $id) {
        id
        type
        label
        knowledgeId: knowledge
        ancestorsContent @skip(if: $skipAncestors) {
          id
          label
          type
        }
        enrichedCaseParameters {
          id
          label
        }
        defaultLanguage
        returnLanguage
        setLanguages
      }
    }
  `,

  getAttachedContentsByProductId: gql`
    query getAttachedContentsByProductId($productId: ID!) {
      contents: getAttachedContentsByProductId(productId: $productId) {
        results {
          ... on Content {
            id
            type
            label
            isOutdated
            published
            labels {
              id
              labelName
              icon
              color
            }
          }
        }
        total
        pages
        page
      }
    }
  `,

  getContentsByProductId: gql`
    query getContentsByProductId(
      $productId: ID!
      $count: Int
      $search: String
    ) {
      contents: getContentsByProductId(
        productId: $productId
        count: $count
        search: $search
      ) {
        results {
          ... on Content {
            id
            type
            label
            isOutdated
            published
            isHidden
            labels {
              id
              labelName
              icon
              color
            }
          }
        }
        total
        pages
        page
      }
    }
  `,

  getContentsByPartialProduct: gql`
    query getContentsByPartialProductQuery(
      $parameters: [JSON!]
      $count: Int
      $search: String
    ) {
      contents: getContentsByPartialProduct(
        parameters: $parameters
        count: $count
        search: $search
      ) {
        results {
          ... on Content {
            id
            type
            label
            isOutdated
            published
            isHidden
            labels {
              id
              labelName
              icon
              color
            }
          }
        }
        total
        pages
        page
      }
    }
  `,

  getAttachedContentsByPartialProduct: gql`
    query getAttachedContentsByPartialProductQuery(
      $parameters: [JSON!]
      $count: Int
    ) {
      contents: getAttachedContentsByPartialProduct(
        parameters: $parameters
        count: $count
      ) {
        results {
          ... on Content {
            id
            type
            label
            isOutdated
            published
            labels {
              id
              labelName
              icon
              color
            }
          }
        }
        total
        pages
        page
      }
    }
  `,

  getArchives: gql`
    query getArchivesQuery(
      $knowledge: String!
      $pageSize: Int
      $page: Int
      $search: String
    ) {
      getArchives(
        knowledge: $knowledge
        pageSize: $pageSize
        page: $page
        search: $search
      ) {
        results {
          ... on ContentArchive {
            id
            content {
              id
              label
              type
              published
              isOutdated
            }
          }
        }
        total
        pages
      }
    }
  `,
  getArchiveById: gql`
    ${CONTENT_FRAGMENT}
    query getArchiveByIdQuery($archiveId: ID!, $lang: String) {
      getArchiveById(archiveId: $archiveId, lang: $lang) {
        id
        content {
          ...ContentFragment
          labelTranslations
          enrichedAuthor {
            username
          }
        }
        descendants {
          ...ContentFragment
        }
        createdAt
        formerMainAncestor {
          id
          label
        }
        formerCaseParents {
          id
          label
        }
        caseArchiveParent {
          id
        }
        author {
          id
          username
          isDeleted
        }
        createdAt
      }
    }
  `,
  countRelatedContentReferences: gql`
    query countRelatedContentReferences($contentId: ID!, $knowledgeId: ID!) {
      countRelatedContentReferences(
        contentId: $contentId
        knowledgeId: $knowledgeId
      )
    }
  `,
  filterContentsQuery: gql`
    query filterContentsQuery(
      $searchFilter: String
      $knowledgeFilters: String
      $productFilters: [JSON!]
      $caseFilters: [String!]
      $statusFilters: [JSON!]
      $accessRestrictionsFilters: [String]
      $labelsFilters: [String]
      $contributionFilter: Boolean
      $pageSize: Int
      $page: Int
    ) {
      filterContents(
        searchFilter: $searchFilter
        knowledgeFilters: $knowledgeFilters
        productFilters: $productFilters
        caseFilters: $caseFilters
        statusFilters: $statusFilters
        accessRestrictionsFilters: $accessRestrictionsFilters
        labelsFilters: $labelsFilters
        contributionFilter: $contributionFilter
        pageSize: $pageSize
        page: $page
      ) {
        results {
          ... on Content {
            id
            type
            knowledgeId: knowledge
            label
            body
            type
            tags
            attachedObjects
            published
            isOutdated
            icon
            path
            parameters
            caseParameters
            author
            accessRestrictions
            isGeneric
            labels {
              id
              labelName
              icon
              color
            }
            parents
            isReadOnly
          }
        }
        total
        pages
        page
      }
    }
  `,
  filterContentsCount: gql`
    query filterContentsQuery(
      $knowledgeFilters: String
      $productFilters: [JSON!]
      $caseFilters: [String!]
      $statusFilters: [JSON!]
      $accessRestrictionsFilters: [String]
      $labelsFilters: [String]
      $contributionFilter: Boolean
      $knowledgeId: ID
    ) {
      filterContents(
        knowledgeFilters: $knowledgeFilters
        productFilters: $productFilters
        caseFilters: $caseFilters
        statusFilters: $statusFilters
        accessRestrictionsFilters: $accessRestrictionsFilters
        labelsFilters: $labelsFilters
        contributionFilter: $contributionFilter
        knowledgeId: $knowledgeId
      ) {
        results {
          ... on Content {
            id
          }
        }
        total
      }
    }
  `,
  retrieveContentDescendents: gql`
    query retrieveContentDescendentsQuery($contentId: ID!, $lang: String) {
      retrieveContentDescendents(contentId: $contentId, lang: $lang) {
        root {
          id
          label
          path
          type
        }
        descendents {
          id
          label
          path
          type
          redirection {
            entityId
            entityType
            isDeleted
            lang
          }
        }
      }
    }
  `,
  updateContent: gql`
    ${PUBLIC_DATA_FRAGMENT}
    ${WORKFLOW_ACTION_FRAGMENT}
    mutation updateContentMutation(
      $id: ID!
      $tags: [String!]
      $path: [ID!]
      $isRestore: Boolean
    ) {
      updateContent(id: $id, tags: $tags, path: $path, isRestore: $isRestore) {
        id
        label
        body
        published
        path
        children {
          id
          label
          body
          type
          redirection {
            entityId
            entityType
            isDeleted
            lang
          }
          returnLanguage
        }
        redirection {
          entityId
          entityType
          isDeleted
          lang
        }
        publicData {
          ...publicDataFragment
        }
        type
        defaultLanguage
        returnLanguage
        setLanguages
        workflowActions {
          ...workflowActionFragment
        }
      }
    }
  `,
  updateContentTranslation: gql`
    ${PUBLIC_DATA_FRAGMENT}
    ${WORKFLOW_ACTION_FRAGMENT}
    mutation updateContentTranslationMutation(
      $id: ID!
      $label: String
      $body: String
      $lang: String
      $mentions: [JSON!]
      $summary: ContentSummaryInput
      $shortSummary: ContentSummaryInput
      $actionItems: ActionItemsInput
      $published: Boolean
      $isHidden: Boolean
    ) {
      updateContentTranslation(
        id: $id
        label: $label
        body: $body
        lang: $lang
        mentions: $mentions
        summary: $summary
        shortSummary: $shortSummary
        actionItems: $actionItems
        published: $published
        isHidden: $isHidden
      ) {
        id
        label
        labelTranslations
        caseParameters
        body
        published
        publishedTranslations
        isHidden
        path
        children {
          id
          label
          body
          type
          redirection {
            entityId
            entityType
            isDeleted
            lang
          }
          returnLanguage
        }
        redirection {
          entityId
          entityType
          isDeleted
        }
        publicData {
          ...publicDataFragment
        }
        type
        defaultLanguage
        returnLanguage
        setLanguages
        updatedAt
        workflowActions {
          ...workflowActionFragment
        }
      }
    }
  `,
  toggleKeyStep: gql`
    mutation toggleKeyStep($id: ID!, $type: String) {
      toggleKeyStep(id: $id, type: $type) {
        id
        type
      }
    }
  `,
  updateContentSettings: gql`
    mutation updateContentSettingsMutation(
      $id: ID!
      $knowledge: String!
      $parameters: [JSON!]
      $caseParameters: [String!]
      $labels: [ID!]
      $accessRestrictions: [ID!]
      $newIndex: Int
    ) {
      updateContentSettings(
        id: $id
        knowledge: $knowledge
        parameters: $parameters
        caseParameters: $caseParameters
        categories: $labels
        accessRestrictions: $accessRestrictions
        newIndex: $newIndex
      ) {
        id
        parameters
        knowledgeId: knowledge
        enrichedParameters
        caseParameters
        labels {
          id
          labelName
          icon
          color
        }
        accessRestrictions
      }
    }
  `,

  upsertContentAction: gql`
    mutation upsertContentActionMutation(
      $contentId: ID!
      $actionId: ID
      $actionPayload: JSON!
      $langs: [String]
      $type: String
      $title: String
    ) {
      upsertContentAction(
        contentId: $contentId
        actionId: $actionId
        actionPayload: $actionPayload
        langs: $langs
        type: $type
        title: $title
      ) {
        id
        actions {
          id
          payload
          type
          langs
          mongoId
          title
        }
      }
    }
  `,

  deleteContentAction: gql`
    mutation deleteContentActionMutation($contentId: ID!, $actionId: ID!) {
      deleteContentAction(contentId: $contentId, actionId: $actionId) {
        id
        actions {
          id
          payload
          type
          langs
          mongoId
          title
        }
      }
    }
  `,

  updateContentPlugins: gql`
    mutation updateContentPluginsMutation($contentId: ID!, $pluginId: ID!) {
      updateContentPlugins(contentId: $contentId, pluginId: $pluginId) {
        id
        plugins
      }
    }
  `,
  relocateContent: gql`
    mutation relocateContentMutation(
      $childId: ID!
      $formerParentId: ID!
      $newParentId: ID!
    ) {
      relocateContent(
        childId: $childId
        formerParentId: $formerParentId
        newParentId: $newParentId
      )
    }
  `,
  upsertVerificationPolicy: gql`
    mutation upsertVerificationPolicy(
      $contentId: ID!
      $verificationRange: Int!
      $verificationDueDate: String
      $setToDraftAtDueDate: Boolean
      $customRoleId: ID
      $userId: ID
      $lang: String
    ) {
      updatedContent: findOrUpsert(
        contentId: $contentId
        verificationRange: $verificationRange
        verificationDueDate: $verificationDueDate
        setToDraftAtDueDate: $setToDraftAtDueDate
        customRoleId: $customRoleId
        userId: $userId
        lang: $lang
      ) {
        id
        label
        isOutdated
        verificationPolicy {
          verificationDueDate
          setToDraftAtDueDate
          verificationRange
          customRoleId
          userId
        }
      }
    }
  `,

  removeVerificationPolicy: gql`
    mutation removeVerificationPolicyMutation($contentId: ID!, $lang: String) {
      removeVerificationPolicy(contentId: $contentId, lang: $lang)
    }
  `,

  upsertPublicationPolicy: gql`
    mutation upsertPublicationPolicyMutation(
      $contentId: ID!
      $publicationDueDate: String!
      $lang: String!
    ) {
      upsertPublicationPolicy(
        contentId: $contentId
        publicationDueDate: $publicationDueDate
        lang: $lang
      ) {
        id
        label
        publicationPolicy {
          publicationDueDate
        }
      }
    }
  `,

  removePublicationPolicy: gql`
    mutation removePublicationPolicyMutation($contentId: ID!, $lang: String!) {
      removePublicationPolicy(contentId: $contentId, lang: $lang)
    }
  `,

  restoreArchiveContent: gql`
    mutation restoreArchiveContentMutation(
      $archiveId: ID!
      $caseParameters: [String!]
    ) {
      restoreArchive(archiveId: $archiveId, caseParameters: $caseParameters) {
        id
      }
    }
  `,

  createSyncContent: gql`
    mutation createSyncContentMutation(
      $id: ID!
      $source: String!
      $externalId: String!
      $lang: String
    ) {
      createSyncContent(
        id: $id
        source: $source
        externalId: $externalId
        lang: $lang
      ) {
        id
        label
        body
        isReadOnly
      }
    }
  `,

  syncContent: gql`
    mutation syncContentMutation($id: ID!, $lang: String) {
      syncContent(id: $id, lang: $lang) {
        id
        label
        body
        isReadOnly
      }
    }
  `,
  unlinkSyncContent: gql`
    mutation unlinkSyncContenttMutation($id: ID!, $lang: String) {
      unlinkSyncContent(id: $id, lang: $lang) {
        id
        label
        body
        isReadOnly
      }
    }
  `,

  unsetContentLanguage: gql`
    mutation unsetContentLanguageMutation($contentId: ID!, $lang: String!) {
      unsetContentLanguage(contentId: $contentId, lang: $lang) {
        label
        body
        setLanguages
        returnLanguage
        defaultLanguage
      }
    }
  `,

  setContentAccess: gql`
    ${PUBLIC_DATA_FRAGMENT}
    mutation setContentAccessMutation(
      $id: ID!
      $accessType: String
      $lang: String
    ) {
      setContentAccess(id: $id, accessType: $accessType, lang: $lang) {
        id
        publicData {
          ...publicDataFragment
        }
      }
    }
  `,
  setPublicDescription: gql`
    ${PUBLIC_DATA_FRAGMENT}
    mutation setPublicDescriptionMutation(
      $id: ID!
      $description: String
      $metaLabel: String
      $lang: String
    ) {
      setPublicDescription(
        id: $id
        description: $description
        metaLabel: $metaLabel
        lang: $lang
      ) {
        id
        publicData {
          ...publicDataFragment
        }
      }
    }
  `,
  addAccessToken: gql`
    ${PUBLIC_DATA_FRAGMENT}
    mutation addAccessTokenMutation(
      $id: ID!
      $name: String
      $expirationDate: String
    ) {
      addToken(id: $id, name: $name, expirationDate: $expirationDate) {
        id
        publicData {
          ...publicDataFragment
        }
      }
    }
  `,
  revokeAccessToken: gql`
    ${PUBLIC_DATA_FRAGMENT}
    mutation revokeAccessTokenMutation($id: ID!, $accessToken: String!) {
      revokeToken(id: $id, accessToken: $accessToken) {
        id
        publicData {
          ...publicDataFragment
        }
      }
    }
  `,

  updateCover: gql`
    mutation updateCoverMutation($id: ID!, $url: String) {
      setContentCover(id: $id, url: $url) {
        id
        cover
      }
    }
  `,

  setRelatedContents: gql`
    mutation setRelatedContentsMutation($id: ID!, $relatedContents: [ID!]) {
      setRelatedContents(id: $id, relatedContents: $relatedContents) {
        id
        relatedContents
      }
    }
  `,

  createWorkflowAction: gql`
    ${WORKFLOW_ACTION_FRAGMENT}
    mutation createContentWorkflowActionMutation(
      $contentId: ID!
      $workflowAction: JSON!
    ) {
      createContentWorkflowAction(
        contentId: $contentId
        workflowAction: $workflowAction
      ) {
        id
        workflowActions {
          ...workflowActionFragment
        }
      }
    }
  `,

  updateWorkflowAction: gql`
    ${WORKFLOW_ACTION_FRAGMENT}
    mutation updateContentWorkflowActionMutation(
      $contentId: ID!
      $workflowActionId: ID!
      $workflowAction: JSON!
    ) {
      updateContentWorkflowAction(
        contentId: $contentId
        workflowActionId: $workflowActionId
        workflowAction: $workflowAction
      ) {
        id
        workflowActions {
          ...workflowActionFragment
        }
      }
    }
  `,

  deleteWorkflowAction: gql`
    ${WORKFLOW_ACTION_FRAGMENT}
    mutation deleteContentWorkflowActionMutation(
      $contentId: ID!
      $workflowActionId: ID!
    ) {
      deleteContentWorkflowAction(
        contentId: $contentId
        workflowActionId: $workflowActionId
      ) {
        id
        workflowActions {
          ...workflowActionFragment
        }
      }
    }
  `,

  orderWorkflowActions: gql`
    ${WORKFLOW_ACTION_FRAGMENT}
    mutation orderContentWorkflowActionsMutation(
      $contentId: ID!
      $orderedWorkflowActionIds: [ID!]
    ) {
      orderContentWorkflowActions(
        contentId: $contentId
        orderedWorkflowActionIds: $orderedWorkflowActionIds
      ) {
        id
        workflowActions {
          ...workflowActionFragment
        }
      }
    }
  `,

  getWorkflowActionLibrary: gql`
    query workflowActionLibraryQuery {
      workflowActionLibrary {
        _id
        title
        description
        type
      }
    }
  `,

  addWorkflowActionLibraryItem: gql`
    mutation addWorkflowActionLibraryItemMutation(
      $item: WorkflowActionLibraryItemInput!
    ) {
      addWorkflowActionLibraryItem(item: $item) {
        _id
        title
        description
        type
        payload
      }
    }
  `,

  updateWorkflowActionLibraryItem: gql`
    mutation updateWorkflowActionLibraryItemMutation(
      $id: ID!
      $item: WorkflowActionLibraryItemInput!
    ) {
      updateWorkflowActionLibraryItem(id: $id, item: $item) {
        _id
        title
        description
        payload
      }
    }
  `,
};
