import gql from 'graphql-tag';
import {
  EMPLOYEES_FRAGMENT,
  PENDING_EMPLOYEES_FRAGMENT,
  USER_FRAGMENT,
} from '../fragments';

export default {
  // QUERIES
  checkUserValidity: gql`
    query checkUserValidity($email: String!) {
      checkUserValidity(email: $email)
    }
  `,
  retrieveUserHelpdeskId: gql`
    ${EMPLOYEES_FRAGMENT}
    mutation retrieveUserHelpdeskIdMutation(
      $id: ID!
      $helpdesk: String!
      $page: Int
      $pageSize: Int
      $filters: [Filter]
      $sort: Sort
      $search: String
    ) {
      retrieveUserHelpdeskId(id: $id, helpdesk: $helpdesk) {
        employees(
          page: $page
          pageSize: $pageSize
          filters: $filters
          sort: $sort
          search: $search
        ) {
          ...EmployeesFragment
        }
      }
    }
  `,

  getUsersWithPermissions: gql`
    query getUsersWithPermissions(
      $permissions: [String!]
      $knowledgeId: String
    ) {
      usersWithPermissions: getUsersWithPermissions(
        permissions: $permissions
        knowledgeId: $knowledgeId
      ) {
        id
        username
        role {
          name
        }
      }
    }
  `,
  searchEditorUsers: gql`
    ${USER_FRAGMENT}
    query searchEditorUsers(
      $page: Int
      $pageSize: Int
      $filters: [Filter]
      $sort: Sort
      $search: String
      $permissions: [String]
    ) {
      usersWithPermissions: searchEditorUsers(
        page: $page
        pageSize: $pageSize
        filters: $filters
        sort: $sort
        search: $search
        permissions: $permissions
      ) {
        results {
          ...UserFragment
        }
        total
        pages
      }
    }
  `,

  getUserById: gql`
    query getUserById($id: ID!) {
      getUser(id: $id) {
        id
        username
      }
    }
  `,

  getUsersByIds: gql`
    query getUsersByIds($ids: [ID!]) {
      getUsersByIds(ids: $ids) {
        id
        username
      }
    }
  `,

  getUserOrDeletedUserById: gql`
    query getUserOrDeletedUserById($id: ID!) {
      getUserOrDeletedUserById(id: $id) {
        id
        username
        isDeleted
      }
    }
  `,

  getUserFromAccessToken: gql`
    query getUserInformationsQuery {
      getUserInformations {
        id
        username
        avatar
        createdAt
        role {
          id
          name
          customRolePermissions {
            rolePermission
          }
        }
        isVerified
        auth {
          websocketData {
            connectionUrl
            connectionGroups
          }
        }
        company {
          id
          integrations {
            integration
          }
          reachableUsers {
            id
            username
          }
          reachableRoles {
            id
            name
          }
          preferences {
            id
            type
            value
          }
          logo
        }
        preferences {
          id
          preference
          value
        }
      }
    }
  `,

  // MUTATIONS
  register: gql`
    mutation registerMutation(
      $username: String!
      $email: String!
      $password: String!
      $company: String!
      $onlyNotify: Boolean!
    ) {
      register(
        username: $username
        email: $email
        password: $password
        company: $company
        onlyNotify: $onlyNotify
      )
    }
  `,
  createUsersInvitation: gql`
    ${PENDING_EMPLOYEES_FRAGMENT}
    mutation createUsersInvitationMutation(
      $users: [JSON]
      $page: Int
      $pageSize: Int
      $filters: [Filter]
      $sort: Sort
      $search: String
    ) {
      createUsersInvitation(users: $users) {
        pendingEmployees(
          page: $page
          pageSize: $pageSize
          filters: $filters
          sort: $sort
          search: $search
        ) {
          ...PendingEmployeesFragment
        }
      }
    }
  `,
  // Non-auth mutation
  verifyToken: gql`
    mutation verifyTokenMutation($token: String!, $tokenType: String!) {
      verifyToken(token: $token, tokenType: $tokenType) {
        id
        email
        company {
          name
        }
      }
    }
  `,
  renewUserInvitations: gql`
    ${PENDING_EMPLOYEES_FRAGMENT}
    mutation renewUserInvitations(
      $invitedUsers: [JSON]
      $page: Int
      $pageSize: Int
      $filters: [Filter]
      $sort: Sort
      $search: String
    ) {
      renewUserInvitations(invitedUsers: $invitedUsers) {
        pendingEmployees(
          page: $page
          pageSize: $pageSize
          filters: $filters
          sort: $sort
          search: $search
        ) {
          ...PendingEmployeesFragment
        }
      }
    }
  `,

  createUserFromInvitation: gql`
    mutation createUserFromInvitationMutation(
      $username: String!
      $email: String!
      $password: String!
      $token: String!
      $tokenType: String!
    ) {
      createUserFromInvitation(
        username: $username
        email: $email
        password: $password
        token: $token
        tokenType: $tokenType
      ) {
        username
        email
      }
    }
  `,

  setManyUsersGroup: gql`
    ${EMPLOYEES_FRAGMENT}
    ${PENDING_EMPLOYEES_FRAGMENT}
    mutation setUsersGroupMutation(
      $groupId: [ID!]
      $userIds: [ID!]
      $page: Int
      $pageSize: Int
      $filters: [Filter]
      $sort: Sort
      $search: String
      $skipEmployees: Boolean!
      $skipPendingEmployees: Boolean!
    ) {
      setUsersGroup(groupId: $groupId, userIds: $userIds) {
        employees(
          page: $page
          pageSize: $pageSize
          filters: $filters
          sort: $sort
          search: $search
        ) @skip(if: $skipEmployees) {
          ...EmployeesFragment
        }
        pendingEmployees(
          page: $page
          pageSize: $pageSize
          filters: $filters
          sort: $sort
          search: $search
        ) @skip(if: $skipPendingEmployees) {
          ...PendingEmployeesFragment
        }
        userGroups {
          id
          name
          reachable
          users {
            id
            username
            email
          }
          usersCount
          hasPendingUsers
        }
      }
    }
  `,
  setManyUsersRole: gql`
    ${EMPLOYEES_FRAGMENT}
    ${PENDING_EMPLOYEES_FRAGMENT}
    mutation setUsersRoleMutationn(
      $customRoleIds: [ID!]!
      $userIds: [ID!]!
      $page: Int
      $pageSize: Int
      $filters: [Filter]
      $sort: Sort
      $search: String
      $skipEmployees: Boolean!
      $skipPendingEmployees: Boolean!
    ) {
      setUsersRole(customRoleIds: $customRoleIds, userIds: $userIds) {
        employees(
          page: $page
          pageSize: $pageSize
          filters: $filters
          sort: $sort
          search: $search
        ) @skip(if: $skipEmployees) {
          ...EmployeesFragment
        }
        pendingEmployees(
          page: $page
          pageSize: $pageSize
          filters: $filters
          sort: $sort
          search: $search
        ) @skip(if: $skipPendingEmployees) {
          ...PendingEmployeesFragment
        }
        roles {
          id
          name
          isCustom
          usersCount
          hasPendingUsers
          customRolePermissions {
            rolePermission
            scope
          }
        }
      }
    }
  `,
  setManyUsersLabels: gql`
    ${EMPLOYEES_FRAGMENT}
    ${PENDING_EMPLOYEES_FRAGMENT}
    mutation setUsersLabelsMutationn(
      $companyUserLabelIds: [ID!]!
      $userIds: [ID!]!
      $page: Int
      $pageSize: Int
      $filters: [Filter]
      $sort: Sort
      $search: String
      $skipEmployees: Boolean!
      $skipPendingEmployees: Boolean!
    ) {
      setUsersLabels(
        companyUserLabelIds: $companyUserLabelIds
        userIds: $userIds
      ) {
        employees(
          page: $page
          pageSize: $pageSize
          filters: $filters
          sort: $sort
          search: $search
        ) @skip(if: $skipEmployees) {
          ...EmployeesFragment
        }
        pendingEmployees(
          page: $page
          pageSize: $pageSize
          filters: $filters
          sort: $sort
          search: $search
        ) @skip(if: $skipPendingEmployees) {
          ...PendingEmployeesFragment
        }
      }
    }
  `,
  updateUser: gql`
    ${USER_FRAGMENT}
    mutation updateUser($user: UserInput) {
      updateUser(user: $user) {
        ...UserFragment
      }
    }
  `,
  deleteManyUsers: gql`
    ${EMPLOYEES_FRAGMENT}
    ${PENDING_EMPLOYEES_FRAGMENT}
    mutation deleteUsersMutation(
      $ids: [ID!]
      $page: Int
      $pageSize: Int
      $filters: [Filter]
      $sort: Sort
      $search: String
      $skipEmployees: Boolean!
      $skipPendingEmployees: Boolean!
    ) {
      deleteUsers(ids: $ids) {
        employees(
          page: $page
          pageSize: $pageSize
          filters: $filters
          sort: $sort
          search: $search
        ) @skip(if: $skipEmployees) {
          ...EmployeesFragment
        }
        pendingEmployees(
          page: $page
          pageSize: $pageSize
          filters: $filters
          sort: $sort
          search: $search
        ) @skip(if: $skipPendingEmployees) {
          ...PendingEmployeesFragment
        }
        userGroups {
          id
          name
          reachable
          users {
            id
            username
            email
          }
          usersCount
          hasPendingUsers
        }
        roles {
          id
          name
          isCustom
          usersCount
          hasPendingUsers
          customRolePermissions {
            rolePermission
            scope
          }
        }
      }
    }
  `,

  getMe: gql`
    query GetUser {
      currentUser {
        username
        givenName
        familyName
        email
        jobtitle
        isVerified
        company {
          logo
        }
      }
    }
  `,

  updateUserSettings: gql`
    mutation updateSettingsMutation($givenName: String, $familyName: String) {
      updateSettings(givenName: $givenName, familyName: $familyName) {
        username
        givenName
        familyName
        email
        jobtitle
      }
    }
  `,

  resetPasswordFromAdmin: gql`
    mutation resetPasswordFromAdmin($userId: ID!) {
      resetPasswordFromAdmin(userId: $userId)
    }
  `,

  resetPassword: gql`
    mutation forgotPasswordMutation($email: String!) {
      forgotPassword(email: $email)
    }
  `,
};
